<script>
  export default {
    name: 'Modal',
    methods: {
      close() {
        this.$emit('close');
      },
    },
  };
</script>

<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">
        <header class="modal-header" >
          <slot name="header">
          <p>◉ <b> TERIMAKASIH </b> ◉</p>
          </slot>
        </header>
        <!--<footer class="modal-footer">
          <button type="button" class="btn-green" @click="close" >Home Page</button>
        </footer>-->
      </div>
    </div>
  </transition>
</template>

<style>
  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal {
    background: #FFFFFF;
    box-shadow: 1px 1px 1px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
  }

  .modal-header,
  .modal-footer {
    padding: 15px;
    display: flex;
  }

  .modal-header {
    position: bottom;
    border-bottom: 1px solid #eeeeee;
    font-size: 15px;
    color: #2b9657;
    justify-content: center;
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
    flex-direction: column;
  }

  .modal-body {
    position: relative;
    padding: 20px 10px;
  }

  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 15px;
    padding: 10px;
    cursor: pointer;
    color: #4AAE9B;
    background: transparent;
  }

  .btn-green {
    color: white;
    background: #27ae60;
    border: 1px solid #27ae60;
    border-radius: 2px;
    font-size: 0px;
  }

  .modal-fade-enter,
  .modal-fade-leave-to {
    opacity: 0;
  }

  .modal-fade-enter-active,
  .modal-fade-leave-active {
    transition: opacity .5s ease;
  }
</style>
