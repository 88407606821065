import { createApp } from 'vue'
import ppdbtk from './ppdbtk.vue'

createApp(ppdbtk).mount('#app')





  



